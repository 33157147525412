import React, { useState, useEffect } from "react";
import "./Styles/global.scss";
import Main from "./Components/Main";

function App() {
  return (
    <>
      <Main />
    </>
  );
}

export default App;
